const dashboardLinks = {
  details: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/dashboards/details/${namespace}/${arrayId}`,

  overview: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/dashboards/details/${namespace}/${arrayId}/overview`,

  sharing: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/dashboards/details/${namespace}/${arrayId}/sharing`,

  settings: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/dashboards/details/${namespace}/${arrayId}/settings`,

  activity: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/dashboards/details/${namespace}/${arrayId}/activity`,
};

export default dashboardLinks;
