import { NotebookSize, NotebookType } from 'api-client';
import useEventListener from 'hooks/useEventListener';
import IframeActions from 'utils/constants/IframeActions';
import { isString } from 'utils/helpers/string';

interface ServerProfile {
  slug: string;
  description?: string;
  display_name: string;
  memory: string;
  cpu: string;
  pricing?: string;
}

export interface ImageProfile {
  slug: string;
  description?: string;
  display_name: string;
  package_list_url?: string;
  beta?: '' | 'True' | 'False';
}
export interface ImagesAndServerProfiles {
  images: Record<string, ImageProfile>;
  servers: Record<string, ServerProfile>;
}

interface ImageAndServerSelections {
  image: NotebookType;
  server: NotebookSize;
}

interface NotebookFileProperties {
  image: NotebookType;
  server: NotebookSize;
}

interface ImagesAndServerProfilesMessageEvent {
  payload: ImagesAndServerProfiles;
  action: IframeActions.TILEDB_AVAILABLE_PROFILES;
}

interface VoilaHasLaunchedMessageEvent {
  action: IframeActions.TILEDB_VOILA_LAUNCHED;
  payload: {
    name: string;
  };
}

interface JupyterhubProgressMessageEvent {
  action: IframeActions.TILEDB_NOTEBOOK_PROGRESS;
  payload: {
    progress: string;
  };
}

interface JupyterhubProfileSelectionsMessageEvent {
  action: IframeActions.TILEDB_JUPYTERHUB_USER_SELECTIONS;
  payload: ImageAndServerSelections;
}

interface JupyterlabCreateNewNotebookMessageEvent {
  action: IframeActions.TILEDB_CREATE_NOTEBOOK;
}

interface JupyterlabLoaderMessageEvent {
  action:
    | IframeActions.TILEDB_SHOW_LOADER_START
    | IframeActions.TILEDB_SHOW_LOADER_STOP;
  payload: {
    title?: string;
  };
}

interface JupyterhubUpdateFilePropertiesMessageEvent {
  action: IframeActions.TILEDB_UPDATE_FILE_PROPERTIES;
  payload: NotebookFileProperties;
}

type IncomingMessageEvents =
  | ImagesAndServerProfilesMessageEvent
  | JupyterhubProgressMessageEvent
  | JupyterhubProfileSelectionsMessageEvent
  | VoilaHasLaunchedMessageEvent
  | JupyterlabCreateNewNotebookMessageEvent
  | JupyterhubUpdateFilePropertiesMessageEvent
  | JupyterlabLoaderMessageEvent
  | string
  | number;

interface IncomingMessageHandlers {
  onJupyterlabLaunching?: () => void;
  onVoilaLaunch?: (name: string) => void;
  onJupyterNotRunning?: () => void;
  onJupyterhubOrJupyterlabLoaded?: () => void;
  onJupyterlabLoaded?: () => void;
  onJupyterSpawnPageReady?: () => void;
  onJupyterProgress?: (progress: number) => void;
  onJupyterError?: () => void;
  onJupyterhubServerAndImageProfilesReceived?: (
    payload: ImagesAndServerProfiles
  ) => void;
  onJupyterhubUserRequestedCredits?: () => void;
  onJupyterLabExtensionOpenFileLoaded?: () => void;
  onJupyterLabShowLoaderRequest?: (title: string) => void;
  onJupyterLabHideLoaderRequest?: (title: string) => void;
  onJupyterAddCredentialsRequest?: () => void;
  onJupyterlabCreateNotebookRequest?: () => void;
  onJupyterhubUpdateFilePropertiesRequest?: (
    payload: NotebookFileProperties
  ) => void;
  onJupyterlabShareNotebookRequest?: (path: string) => void;
  onJupyterhubUserImageAndServerProfileSelection?: (
    selections: ImageAndServerSelections
  ) => void;
}

const useOnJupyterIncomingMessage = (handlers: IncomingMessageHandlers) => {
  const {
    onJupyterlabLaunching,
    onJupyterNotRunning,
    onJupyterSpawnPageReady,
    onJupyterProgress,
    onJupyterError,
    onJupyterhubOrJupyterlabLoaded,
    onJupyterlabLoaded,
    onJupyterhubUserRequestedCredits,
    onJupyterhubServerAndImageProfilesReceived,
    onJupyterLabExtensionOpenFileLoaded,
    onJupyterLabShowLoaderRequest,
    onVoilaLaunch,
    onJupyterLabHideLoaderRequest,
    onJupyterAddCredentialsRequest,
    onJupyterlabCreateNotebookRequest,
    onJupyterhubUpdateFilePropertiesRequest,
    onJupyterlabShareNotebookRequest,
    onJupyterhubUserImageAndServerProfileSelection,
  } = handlers;

  useEventListener(
    'message',
    async (event: MessageEvent<IncomingMessageEvents>) => {
      if (event.data === IframeActions.TILEDB_JUPYTERLAB_LAUNCHING) {
        onJupyterlabLaunching?.();
      }

      if (event.data === IframeActions.TILEDB_JUPYTER_NOT_RUNNING) {
        onJupyterNotRunning?.();
      }

      if (
        typeof event.data === 'object' &&
        event.data.action === IframeActions.TILEDB_AVAILABLE_PROFILES
      ) {
        onJupyterhubServerAndImageProfilesReceived?.(event.data.payload);
      }

      if (event.data === IframeActions.TILEDB_SPAWN_READY) {
        onJupyterSpawnPageReady?.();
      }

      if (
        typeof event.data === 'object' &&
        event.data.action === IframeActions.TILEDB_NOTEBOOK_PROGRESS
      ) {
        const progressText = event.data.payload.progress;
        const progressNumber = Number(progressText);

        onJupyterProgress?.(progressNumber);
      }

      if (event.data === IframeActions.TILEDB_ERROR_500) {
        onJupyterError?.();
      }

      if (event.data === IframeActions.TILEDB_JUPYTERHUB_OR_JUPYTERLAB_LOADED) {
        onJupyterhubOrJupyterlabLoaded?.();
      }

      if (
        typeof event.data === 'object' &&
        event.data.action === IframeActions.TILEDB_JUPYTERHUB_USER_SELECTIONS
      ) {
        const { image, server } = event.data.payload;
        onJupyterhubUserImageAndServerProfileSelection?.({ image, server });
      }

      if (event.data === IframeActions.TILEDB_JUPYTERLAB_LOADED) {
        onJupyterlabLoaded?.();
      }

      if (event.data === IframeActions.TILEDB_REQUEST_CREDITS) {
        onJupyterhubUserRequestedCredits?.();
      }

      if (event.data === IframeActions.TILEDB_OPEN_FILE_EXTENSION_LOADED) {
        onJupyterLabExtensionOpenFileLoaded?.();
      }

      /**
       * Case Jupyter prompt options plugin sends message to open
       * "Add credentials" modal. We show to the user and send back
       * a message to the iframe upon success in order to update the
       * credentials select input with the updated ones.
       */
      if (event.data === IframeActions.TILEDB_ADD_CREDENTIALS) {
        onJupyterAddCredentialsRequest?.();
      }

      if (
        typeof event.data === 'object' &&
        event.data.action === IframeActions.TILEDB_CREATE_NOTEBOOK
      ) {
        onJupyterlabCreateNotebookRequest?.();
      }

      if (
        isString(event.data) &&
        event.data.startsWith(IframeActions.TILEDB_NOTEBOOK_SHARING_PREFIX)
      ) {
        const notebookCloudPath = event.data.slice(
          IframeActions.TILEDB_NOTEBOOK_SHARING_PREFIX.length
        );
        onJupyterlabShareNotebookRequest?.(notebookCloudPath);
      }

      if (
        typeof event.data === 'object' &&
        event.data.action === IframeActions.TILEDB_SHOW_LOADER_START
      ) {
        const title = event.data?.payload?.title || 'Loading';

        onJupyterLabShowLoaderRequest?.(title);
      }

      if (
        typeof event.data === 'object' &&
        event.data.action === IframeActions.TILEDB_SHOW_LOADER_STOP
      ) {
        const title = event.data?.payload?.title || 'Completed';

        onJupyterLabHideLoaderRequest?.(title);
      }

      if (
        typeof event.data === 'object' &&
        event.data.action === IframeActions.TILEDB_VOILA_LAUNCHED
      ) {
        onVoilaLaunch?.(event.data.payload.name);
      }

      if (
        typeof event.data === 'object' &&
        event.data.action === IframeActions.TILEDB_UPDATE_FILE_PROPERTIES
      ) {
        const { payload } = event.data;
        const { image, server } = payload;
        onJupyterhubUpdateFilePropertiesRequest?.({
          image,
          server,
        });
      }
    }
  );
};

export default useOnJupyterIncomingMessage;
