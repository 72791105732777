import { useEffect, useRef } from 'react';

function useInterval(
  callback: () => void,
  delay: number,
  immediate?: boolean
): void {
  const savedCallback = useRef<Function>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Execute callback if immediate is set.
  useEffect(() => {
    if (!immediate) {
      return;
    }
    savedCallback.current?.();
  }, [immediate]);

  // Set up the interval.
  useEffect(() => {
    function tick(): void {
      savedCallback.current?.();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return (): void => clearInterval(id);
    }
  }, [delay]);
}

export default useInterval;
