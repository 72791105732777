import { QueryKey } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import useMount from 'hooks/useMount';
import queryClient from 'utils/helpers/queryClient';

// Hook that subscribes to a list of query keys
// and fires the callback function when an `update` event occurs
const useQueryDataListener = <T>(
  queryKey: QueryKey,
  callback: (data: T) => void
) => {
  useMount(() => {
    // Subscribe to changes in the cache for the specific query key
    const unsubscribe = queryClient.getQueryCache().subscribe((event) => {
      // Check if the query key matches and the data has been updated
      if (
        event?.type === 'updated' &&
        event.query.state.fetchStatus === 'idle' &&
        event?.query?.queryKey.some((value: any) => queryKey.includes(value))
      ) {
        const data = event.query.state.data?.data;
        if (data) {
          callback(data);
        }
      }
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  });
};

export default useQueryDataListener;
