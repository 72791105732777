import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import Typography, { SizeVariants } from 'components/Typography';
import './Avatar.scss';

export const SIZES = ['xs', 'small', 'medium', 'large', 'xl'] as const;

export type AvatarSizes = (typeof SIZES)[number];

export interface AvatarProps {
  logo?: string;
  size: AvatarSizes;
  rounded?: boolean;
  namespace: string;
  className?: string;
  ariaLabel?: string;
  dataTestId?: string;
}

function getFirstLetter(namespace: string): string {
  const firstLetter = namespace ? namespace[0] : '-';
  return firstLetter.toUpperCase();
}

export const Avatar: React.FC<AvatarProps> = (props) => {
  const {
    logo,
    rounded,
    size,
    namespace,
    className,
    ariaLabel,
    dataTestId = 'TDB-Avatar',
  } = props;
  const [imgError, setImgError] = useState(false);
  const typographySize: SizeVariants = useMemo(() => {
    if (size === 'xs') {
      return 'body-sm';
    } else if (size === 'small') {
      return 'body-md';
    } else if (size === 'medium') {
      return 'heading-xs';
    } else if (size === 'large') {
      return 'body-xl';
    }
    return 'body-xl';
  }, [size]);

  return (
    <div
      className={classNames('TDB-Avatar', className, `TDB-Avatar--${size}`, {
        'TDB-Avatar--no-logo': !logo,
        'TDB-Avatar--rounded': rounded,
      })}
      data-testid={dataTestId}
      aria-label={ariaLabel}
    >
      {!logo && imgError ? (
        <Typography
          className="TDB-Avatar__Placeholder"
          data-testid="TDB-Avatar__Placeholder"
          fontSize={typographySize}
          fontWeight="700"
        >
          {getFirstLetter(namespace)}
        </Typography>
      ) : (
        <img
          alt={`${namespace}'s logo`}
          // logo may come from the API in JSON as base64 string
          // If the logo is truthy we should render it as-is
          // If the logo is falsy we should try to fetch the logo
          // from the internal end point which may return the image in png format
          src={logo || `/v1/api/logo/namespace/${namespace}`}
          data-testid="TDB-Avatar__img"
          onError={() => setImgError(true)}
        />
      )}
    </div>
  );
};

export default Avatar;
