const dashboardRouterPaths = {
  root: '/dashboards',
  details: '/dashboards/details/:namespace/:arrayId',
  sharing: '/dashboards/details/:namespace/:arrayId/sharing',
  overview: '/dashboards/details/:namespace/:arrayId/overview',
  settings: '/dashboards/details/:namespace/:arrayId/settings',
  activity: '/dashboards/details/:namespace/:arrayId/activity',
};

export default dashboardRouterPaths;
