import { equals } from 'ramda';
import { matchPath } from 'react-router-dom';
import {
  arrayRouterPaths,
  fileRouterPaths,
  notebookRouterPaths,
  biomedicalImagingRouterPaths,
  udfRouterPaths,
  vectorSearchRouterPaths,
  vcfRouterPaths,
  somaRouterPaths,
  groupRouterPaths,
  geometryRouterPaths,
  mlModelRouterPaths,
  rasterRouterPaths,
  pointCloudRouterPaths,
  dashboardRouterPaths,
} from 'utils/routerPaths';

const rootPaths = [
  arrayRouterPaths.details,
  notebookRouterPaths.details,
  fileRouterPaths.details,
  somaRouterPaths.details,
  biomedicalImagingRouterPaths.details,
  udfRouterPaths.details,
  groupRouterPaths.details,
  vcfRouterPaths.details,
  vectorSearchRouterPaths.details,
  mlModelRouterPaths.details,
  geometryRouterPaths.details,
  pointCloudRouterPaths.details,
  rasterRouterPaths.details,
  dashboardRouterPaths.details,
];

interface AssetParams {
  namespace: string;
  arrayId?: string;
  groupId?: string;
}

/**
 * Function that checks if two pathnames belong to the same level.
 * This is the case with Asset Details, under Asset details there are many sub-pages.
 * e.g. /arrays/details/TileDB/array-1/overview & /arrays/details/TileDB/array-1/settings
 */
const arePathnamesInSameLevel = (
  pathname1: string | undefined,
  pathname2: string | undefined
) => {
  if (!pathname1 || !pathname2) {
    return false;
  }

  if (pathname1 === pathname2) {
    return true;
  }

  const found = rootPaths.some((rootpath) => {
    const matches = matchPath<AssetParams>(pathname1, {
      path: rootpath,
      exact: false,
    });

    if (!!matches) {
      const matchesSecondPathname = matchPath<AssetParams>(pathname2, {
        path: rootpath,
        exact: false,
      });

      return equals(matches.params, matchesSecondPathname?.params);
    }
    return false;
  });

  return found;
};

export default arePathnamesInSameLevel;
